<template>
  <div class="container main text-center">
    <div class="row form-signin w-100 m-auto">
      <img class="profile-img"
        src="https://lh5.googleusercontent.com/-b0-k99FZlyE/AAAAAAAAAAI/AAAAAAAAAAA/eu7opA4byxI/photo.jpg?sz=120" />
      <h1>Login Form</h1>
      {{ error.message }}
      <form @submit="onSubmit" class="needs-validation">
        <div class="mb-3">
          <label for="email" class="form-label">Email address</label>
          <input type="text" v-model="email" class="form-control has-validation" id="email"
            placeholder="name@example.com" autofocus required>
          <div v-if="error.email" class="invalid-feedback">{{ error.email }}</div>
        </div>
        <div class="mb-3">
          <label for="password" class="form-label">password </label>
          <input type="password" v-model="password" class="form-control has-validation" id="password"
            placeholder="****">

          <div v-if="error.password" class="invalid-feedback">{{ error.password }}</div>

        </div>

        <input type="submit" value="Login" class="w-100 btn btn-lg btn-primary" />
      </form>
    </div>
  </div>
  <div class="container" v-if="loading">
    <div class="row">
      <div class="col">
        <Spinner />
      </div>
    </div>
  </div>
</template>
<script>
import { Auth } from '../services/Auth';
import Spinner from '../components/Spinner.vue';
import { useUserStore } from '../stores/userstore'
export default {
  name: 'login',
  components: {
    Spinner
  },
  setup() {
    const store = useUserStore();

    return {
      store
    }
  },
  data() {
    return {
      email: '',
      password: '',
      error: [],
      loading: false,


    }
  },
  created() {
    const token = localStorage.getItem('authToken');
    if (token) {
      //  console.log(token);
      this.$router.push('/');

    } else {
      // console.log('No Token Found');
    }
    // console.log();
  },
  methods: {
    async onSubmit(e) {
      e.preventDefault()
      if (!this.email) {
        alert('Please Enter User email')
        return
      }
      if (!this.password) {
        alert('Please Enter User password')
        return
      }
      // alert('login');
      const form = {
        "email": this.email,
        "password": this.password
      }
      try {
        this.loading = true;
        const token = await Auth.login(form);
        // console.log(token.data.data);
        this.store.isLogin = true;
        localStorage.setItem("userId", token.data.data.user.id);
        localStorage.setItem("userName", token.data.data.user.name);
        localStorage.setItem("userEmail", token.data.data.user.email);
        localStorage.setItem("authToken", token.data.data.access_token);
        // this.$swal('Welcome !!!');
        setTimeout(function() {
                    location.reload(true);
                }, 100);
        this.loading = false;
        
        // this.$parent.isLogin = true;
        // console.log(this.$parent.isLogin);
        // console.log(this.$emit('login', true));
        this.$router.push('/');
      } catch (error) {
        console.log("this is error ", error)
        if (error.message){
          this.$swal(
            'Login Error Backend Not Found',
            error.message,
            'error'
          );
          this.loading = false;
        }
        if (error.response.status === 422) {
          console.log(error.response.data.errors.email);
          this.error = error.response.data.errors;
          this.loading = false;

          var password = document.getElementById("password");
          var email = document.getElementById("email");
          if (this.error.email) email.className += " is-invalid";
          if (this.error.password) password.className += " is-invalid";
          this.$swal(
            'Login Validation fail',
            error.response.data.message,
            'error'
          );
        }
        if (error.response.status == 401) {
          console.log(error.response.data.message);
          this.error = error.response.data;
          this.loading = false;
          this.$swal(
            'Login Credentials Error',
            error.response.data.message,
            'error'
          );
        }
        // console.log(errors.value.dataContent);
      }

    },
  },
}
</script>
<style scoped>
.main {
  padding-top: 10%;
}


.form-signin {
  max-width: 430px;
  padding: 15px;
}

.profile-img {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
</style>